import { graphql } from "gatsby";
import * as React from "react";
import Errors from "../Components/Errors/Errors";
import Layout from "../Components/Layout/Layout";
// import OutletGallerySection from "../Components/Outlets/OutletGallerySection";
// import OutletHeaderSection from "../Components/Outlets/OutletHeaderSection";
// import OutletInfoSection from "../Components/Outlets/OutletInfoSection";
// import OutletMapSection from "../Components/Outlets/OutletMapSection";
// import OutletsContent from "../Components/Templates/OutletsContent";

export const query = graphql`
  query OutletpageQuery {
    page: allSanityOutlets {
      edges {
        node {
          meta_desc
          meta_title
          title
          slug {
            current
          }
          outlets_phoneno
          outlets_main_image {
            asset {
              _id
            }
          }
          outlets_info_section {
            outlets_info_descs
          }
          outlets_info_desc
          outlets_highlighted_title
          outlets_gallery_section {
            asset {
              _id
            }
          }
          outlets_email
          outlets_address {
            children {
              text
            }
          }
          outlets_short_description
          id
          location
        }
      }
    }
  }
`;

const outlets = (props) => {
  // console.log(props, "sad");
  const { errors, location } = props;
  // console.log(data, "df");
  if (errors) {
    return <Errors errors={errors} />;
  }

  return (
    <Layout location={location}>
      {/* <OutletsContent /> */}
      {/* <OutletHeaderSection data={props} />
      <OutletInfoSection data={props} />
      <OutletMapSection data={props} />
      <OutletGallerySection data={props} /> */}
    </Layout>
  );
};

export default outlets;
